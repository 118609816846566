import Vue from "vue"
import _ from "lodash"
import ep from "~/api/endpoint"
import menu from "./modules/menu"
import icon from "./modules/icon"
import platforms from "./modules/platforms"
import smartchat from "./modules/smartchat"
import auto_reply from "./modules/auto-reply"
import websocket from "./modules/websocket"
import color_palette from "./modules/color-palette"
import dashboard from "./modules/dashboard"
import broadcast from "./modules/broadcast"
import saved_reply from "./modules/saved-reply"
import setting from "./modules/setting"
import team from "./modules/team"
import online_status from "./modules/online-status"
import filter from "./modules/filter"
import auto_detect from "./modules/auto-detect"
import max_panel from "./modules/max-panel"
import contact_profile from "./modules/contact-profile"

const is_dev = process.env.app_env === "development"
let domain = _.chain(process)
  .get("env.web_url")
  .split("//")
  .get("[1]")
  .split("/")
  .get("[0]")
  .split(":")
  .get("[0]")
  .value()
domain = _.chain(domain).split(".").slice(1).join(".").value()

const state = () => ({
  public_pages: ["login", "sign-up", "autoreply-testing"],
  oho_user: null,
  has_business: false,
  business: {},
  oho_member: null,
  oho_member_preferences: null,
  my_profile_dialog_visible: false,
  owners: 0,
  alert: [],
  window_focused: true,
  accept_file_types:
    ".jpg, .jpeg, .png, .gif, .mp4, .webm, .ogv, .mov, .avi, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
  full_page: [
    "auto-reply-detail",
    "auto-reply-facebook-comment-detail",
    "broadcast-create",
    "broadcast-detail",
  ],
  channels: [],
  checked_channels: [],
  show_case_dialog: false,
  show_end_case_dialog: false,
  current_active_case: null,
  fetch_case_list: false,
  case_list_current_contact: [],
  case_current_contact_info: {
    total: 0,
    limit: 0,
    skip: 0,
  },
  case_list_current_contact_loading: false,
  contact_attribute: [],
  my_member_list: [],
  current_subscription_quota: {},
  alert_changes_role: {},
})

const mutations = {
  setOhoUser(state, user) {
    state.oho_user = user
  },
  setHasBusiness(state, status) {
    state.has_business = status
  },
  setCheckedChannels(state, data) {
    state.checked_channels = data
  },
  setChannels(state, data = []) {
    state.channels = data
  },
  setBusiness(state, data = {}) {
    state.business = data
  },
  setOhoMember(state, member) {
    state.oho_member = member
  },
  setOhoMemberRolePermission(state, role_permission) {
    state.oho_member.member.role_permission = role_permission
  },
  addAlert(state, data = {}) {
    let find_exist_alert = null
    const alert_id = _.get(data, "id")

    if (alert_id) {
      find_exist_alert = _.findIndex(state.alert, { id: alert_id })
    }

    if (find_exist_alert < 0) {
      state.alert.push(data)
    }
  },
  removeAlert(state, id) {
    const index = _.findIndex(state.alert, { id: id })
    state.alert.splice(index, 1)
  },
  setMyProfileDialogVisible(state, is_visible) {
    state.my_profile_dialog_visible = is_visible
  },
  setOwners(state, owners) {
    state.owners = owners
  },
  setWindowFocused(state, status) {
    state.window_focused = status
  },
  setShowCaseDialog(state, status) {
    state.show_case_dialog = status
  },
  setShowEndCaseDialog(state, status) {
    state.show_end_case_dialog = status
  },
  setCurrentActiveCase(state, id) {
    state.current_active_case = id
  },
  setFetchCaseList(state, data) {
    state.fetch_case_list = data
  },
  setCaseListCurrentContact(state, data) {
    state.case_list_current_contact = data
  },
  setCaseCurrentContactInfo(state, data) {
    state.case_current_contact_info = data
  },
  setCaseListCurrentContactLoading(state, data) {
    state.case_list_current_contact_loading = data
  },
  setContactAttribute(state, data) {
    state.contact_attribute = data
  },
  setMyMemberList(state, data) {
    state.my_member_list = data
  },
  addTeamToMember(state, team_id) {
    if (
      !_.isEmpty(team_id) &&
      !_.includes(state.oho_member.member.teams, team_id)
    ) {
      state.oho_member.member.teams.push(team_id)
    }
  },
  removeTeamFromMember(state, team_id) {
    const index = _.findIndex(
      state.oho_member.member.teams,
      (team) => team === team_id
    )

    if (index > -1) {
      state.oho_member.member.teams.splice(index, 1)
    }
  },
  removeTeamFromMemberByTeamList(state, remove_team_ids) {
    state.oho_member.member.teams = state.oho_member.member.teams.filter(
      (team) => !remove_team_ids.includes(team)
    )
  },
  setOhoMemberPreferences(state, data) {
    state.oho_member_preferences = data
  },
  setCurrentSubscriptionQuota(state, data) {
    state.current_subscription_quota = data
  },
  setAlertChangeRoleDialog(state, data) {
    state.alert_changes_role = data
  },
}

const actions = {
  async nuxtServerInit({ state, dispatch }, { route }) {
    const business_id =
      _.get(route, "params.biz_id") || this.$cookies.get("oho_current_biz")

    if (route.name === "logout") return

    // if (state.auth.user && !this.$cookies.get("oho_user_token")) {
    if (state.auth.user) {
      await dispatch("authFacebook")
    }

    if (this.$cookies.get("oho_user_token")) {
      await dispatch("authOhoUser")
    }

    if (!state.oho_user || route.name === "invite") return

    if (this.$cookies.get("oho_invite_token")) {
      await dispatch("authAcceptInvite")
    }

    await dispatch("checkBusiness")

    if (!_.get(route, "params.biz_id") && !this.$cookies.get("oho_current_biz"))
      return
    await dispatch("authOhoMember", business_id)
    if (!state.oho_member) return
    dispatch("getContactAttribute")
    await dispatch("getBusiness", business_id)
    await dispatch("getChannels")
    dispatch("announcementFeature")
    dispatch("informAlertShow")
    await dispatch("getOhoMemberPreferences")
  },
  announcementFeature() {
    // const version = process.env.version
    const announcement = this.$cookies.get("announcement")
    // if (version.match(/1.5/g) && announcement === undefined)
    if (announcement === undefined) {
      this.$cookies.set("announcement", "", { path: "/" })
    }
  },
  informAlertShow({ state, commit }) {
    const { business, channels } = state
    let data = null
    const exceed_quota =
      business.is_used_exceed_quota && !business.is_currently_free_trial
    const exceed_plan =
      business.is_used_exceed_plan && !business.is_currently_free_trial
    const free_trial_exceed_quota =
      business.is_currently_free_trial && business.is_used_exceed_quota
    const free_trial_exceed_plan =
      business.is_currently_free_trial && business.is_used_exceed_plan
    const free_trial_start =
      business.is_currently_free_trial && !business.is_used_exceed_plan
    const business_lock =
      business.is_disabled && !business.is_currently_free_trial
    const prefix_path = `/business/${_.get(state.business, "_id")}`

    const is_business_integration_incomplete = channels.find(
      (d) => d.connection_status == "incomplete"
    )

    if (exceed_quota) {
      data = {
        id: "0001",
        text: "พบการใช้งานเกินขีดจำกัดของแพ็กเกจปัจจุบัน",
        type: "error",
        action_label: "ไปยังแพ็กเกจและการชำระเงิน",
        link: `${prefix_path}/setting/payment`,
        closable: false,
      }
    } else if (exceed_plan || business_lock) {
      data = {
        id: "0002",
        text: "โปรดต่ออายุการใช้งาน",
        type: "error",
        action_label: "ไปยังแพ็กเกจและการชำระเงิน",
        link: `${prefix_path}/setting/payment`,
        closable: false,
      }
    } else if (free_trial_exceed_quota) {
      data = {
        id: "0003",
        text: "ใช้งานเกินขีดจำกัดของการทดลองใช้งานฟรี",
        type: "error",
        action_label: "ไปยังแพ็กเกจและการชำระเงิน",
        link: `${prefix_path}/setting/payment`,
        closable: false,
      }
    } else if (free_trial_exceed_plan) {
      data = {
        id: "0004",
        text: "สิ้นสุดการทดลองใช้งานฟรี",
        type: "error",
        action_label: "เลือกแพ็กเกจ",
        link: `${prefix_path}/setting/payment?show=plan`,
        closable: false,
      }
    } else if (is_business_integration_incomplete) {
      data = {
        id: "0005",
        text: "พบปัญหาในการเชื่อมต่อกับช่องทาง",
        type: "warning",
        action_label: "ไปที่การตั้งค่า",
        link: `${prefix_path}/setting/integration`,
        closable: false,
      }
    } else if (free_trial_start) {
      data = {
        id: "0006",
        text: `สามารถทดลองใช้งานธุรกิจนี้ฟรีถึง <b>${this.$formatDate.date(
          business.free_trial_end_at
        )}</b>`,
        type: "info",
        action_label: "เลือกแพ็กเกจ",
        link: `${prefix_path}/setting/payment?show=plan`,
        closable: false,
      }
    }

    commit("addAlert", data)
  },
  async authFacebook() {
    const access_token = this.$auth.strategy.token.get()

    try {
      const data = {
        strategy: "facebook",
        access_token: _.split(access_token, "Bearer ")[1],
      }
      const res = await this.$ohoApi.$post(ep.authentication_user, data)
      const user_id = _.get(res, "user._id")
      const token = _.get(res, "accessToken")
      const created_token_at = _.get(res, "authentication.payload.iat")
      const max_age = 60 * 60 * 24 * 30
      let option = {
        domain,
        path: "/",
        maxAge: max_age,
      }
      if (is_dev) option = _.omit(option, ["domain"])
      this.$cookies.set("oho_user_token", token, option)
      this.$cookies.set("oho_user_id", user_id, option)
      this.$cookies.set("oho_created_token_at", created_token_at, option)
    } catch (error) {
      this.$logger.error("Auth facebook error => ", error)
    }
  },
  async authOhoUser({ commit }) {
    const user_id = this.$cookies.get("oho_user_id")

    try {
      const res = await this.$ohoApi.$get(`${ep.user}/${user_id}`)
      commit("setOhoUser", res)
    } catch (error) {
      this.$logger.error("Auth user error => ", error)
    }
  },
  async authAcceptInvite() {
    try {
      const res = await this.$ohoApi.$patch(
        `${ep.user_accepted_invitation}/${this.$cookies.get(
          "oho_invite_token"
        )}`
      )
      const business_id = _.get(res, "business_id")
      this.$cookies.set("oho_current_biz", business_id, {
        path: "/",
      })

      // [TODO : Nick] ปรับ default หน้า smartchat ให้เป็นหน้าแชทของคุณชั่วคราว
      // if (process.client) window.location = `/business/${business_id}/smartchat`
      if (process.client)
        window.location = `/business/${business_id}/smartchat?status=me`
    } catch (error) {
      this.$logger.error(
        "Accept invite error => ",
        _.get(error, "response.data")
      )
      throw error
    } finally {
      this.$cookies.remove("oho_invite_token", {
        path: "/",
      })
    }
  },
  async checkBusiness({ commit }) {
    try {
      const res = await this.$ohoApi.$get(ep.my_member_list)
      const total = _.get(res, "total", 0)
      if (total > 0) commit("setHasBusiness", true)
    } catch (error) {
      this.$logger.error("Check business error => ", error)
    }
  },
  async authOhoMember({ commit }, business_id) {
    try {
      const res = await this.$ohoApi.$post(ep.authentication_member_login, {
        // business_id: this.$cookies.get("oho_current_biz"),
        business_id: business_id,
      })
      commit("setOhoMember", res)
    } catch (error) {
      this.$logger.error("Auth member error => ", error)
      this.$cookies.remove("oho_current_biz", {
        path: "/",
      })
    }
  },
  async getBusiness({ commit, dispatch }, business_id) {
    // const business_id = this.$cookies.get("oho_current_biz")
    let id = business_id
    if (process.client) id = _.get($nuxt, "$route.params.biz_id")

    const end_point = `${ep.business}/${id}`

    try {
      const res = await this.$ohoMemberApi.$get(end_point)
      commit("setBusiness", res)
      dispatch("informAlertShow")
    } catch (error) {
      this.$logger.error("Get business error => ", error)
    }
  },
  async getChannels({ state, commit, getters }, query) {
    try {
      const res = await this.$ohoMemberApi.get(ep.channel, {
        params: {
          $limit: 200,
          "$sort[order]": 1,
          ...query,
        },
      })
      const data = _.get(res, "data.data", [])
      const channels = data.map((d) => {
        const platform = getters.app_platforms.find((p) => p.name == d.platform)
        return {
          platform_name: platform.display_name,
          ...platform,
          ...d,
        }
      })
      const checked = channels.map((d) => d._id)
      commit("setChannels", channels)
      commit("setCheckedChannels", checked)
    } catch (error) {}
  },
  async getContactAttribute({ commit }) {
    try {
      const res = await this.$ohoMemberApi.get(ep.contact_attribute, {
        params: {
          $limit: 100,
          // "$populate[path]": "attribute_id",
        },
      })

      commit("setContactAttribute", _.get(res, "data.data", []))
    } catch (error) {
      console.log(error)
    }
  },
  logout({ commit }) {
    this.$auth.logout()
    commit("setOhoUser", null)

    if (is_dev) {
      this.$cookies.removeAll()
    } else {
      this.$cookies.remove("oho_user_token", {
        domain,
        path: "/",
      })
      this.$cookies.remove("oho_user_id", {
        domain,
        path: "/",
      })
      this.$cookies.remove("oho_created_token_at", {
        domain,
        path: "/",
      })
      this.$cookies.remove("announcement", {
        path: "/",
      })
      this.$cookies.remove("oho_current_biz", {
        path: "/",
      })
      this.$cookies.remove("oho_fb_alert_perm", {
        path: "/",
      })
      this.$cookies.remove("oho_invite_token", {
        path: "/",
      })
    }
  },
  sendCookiesToFlutter() {
    if (!window.OhoFlutterConnect) return

    const data = {
      action: "send-oho-user-data",
      oho_user_token: this.$cookies.get("oho_user_token"),
      oho_user_id: this.$cookies.get("oho_user_id"),
    }

    window.OhoFlutterConnect.postMessage(JSON.stringify(data))
  },
  toggleCaseDialog({ commit }, { status, case_id }) {
    commit("setShowCaseDialog", status)
    commit("setCurrentActiveCase", case_id)
  },
  toggleEndCaseDialog({ commit }, { status, case_id }) {
    commit("setShowEndCaseDialog", status)
    commit("setCurrentActiveCase", case_id)
  },

  informDeleteChannel({ state, commit }) {
    const prefix_path = `/business/${_.get(state.business, "_id")}`
    const data = {
      id: "0007",
      text: "มีช่องทางถูกลบโดยสมาชิก อาจส่งผลกระทบต่อฟีเจอร์ที่ตั้งค่าไว้ เช่น แชทบอท บรอดแคสต์",
      type: "warning",
      action_label: "ดูการเชื่อมต่อช่องทาง",
      link: `${prefix_path}/setting/integration`,
      closable: true,
    }

    commit("addAlert", data)
  },

  async getOhoMemberPreferences({ commit, dispatch }) {
    try {
      const res = await this.$ohoMemberApi.get(ep.member_preference, {
        params: {
          $select: "is_acked_channel_deletion",
        },
      })

      const data = _.get(res, "data[0]")
      const is_acked_channel_deletion = _.get(
        data,
        "is_acked_channel_deletion",
        true
      )

      if (data) {
        commit("setOhoMemberPreferences", data)

        if (!is_acked_channel_deletion) {
          dispatch("informDeleteChannel")
        }
      }
    } catch (error) {
      this.$logger.error("Error get oho member preference", error)
    }
  },
  async getCurrentSubscriptionQuota({ commit, state }) {
    try {
      const res = await this.$ohoMemberApi.$get(
        ep.business_subscription_monthly_quota,
        {
          params: {
            is_current_quota: true,
            business_id: state.business._id,
          },
        }
      )
      commit("setCurrentSubscriptionQuota", _.get(res, "data[0]", {}))
    } catch (error) {
      this.$logger.error(_.get(error, "response.data"))
    }
  },
}

const getters = {
  // Return Channel by filter(Array) and condition(Function)
  getChannels:
    (state) =>
    (filter_channel = [], condition) => {
      let channels = state.channels
      let condition_filter = []

      if (condition) {
        condition_filter.push(condition)
      }
      if (filter_channel != 0) {
        const filter = (d) => filter_channel.includes(d.platform)
        condition_filter.push(filter)
      }

      return (
        channels.filter((d) => condition_filter.every((con) => con(d))) || []
      )
    },
  // Return => true : false, Channel all of disable chatbot by filter
  getAllOfDisableChatbot:
    (state, getters) =>
    (filter_channel = [], condition) => {
      const channels = getters.getChannels(filter_channel, condition)

      return channels.every((d) => d.is_enable_chatbot === false)
    },
  getCaseListCurrentContact: (state) => () => {
    return state.case_list_current_contact.filter((item) => {
      const involved_member = _.get(item, "involved_members") || []
      return !(
        _.get(item, "status") === "inprogress" && _.isEmpty(involved_member)
      )
    })
  },
}

const modules = {
  platforms,
  menu,
  icon,
  smartchat,
  auto_reply,
  websocket,
  color_palette,
  dashboard,
  broadcast,
  setting,
  team,
  online_status,
  filter,
  saved_reply,
  auto_detect,
  max_panel,
  contact_profile,
}

const strict = false

export default { state, mutations, actions, getters, modules, strict }
